import React, { Component } from "react";
import { Container } from "react-bootstrap";
import classes from "./About.module.css";

export default class About extends Component {
  render() {
    return (
      <Container className={classes.About}>
        <h3>{"About us: \n"}</h3>
        <p>
          {
            "Milano Clothing Company has been serving the Okanagan for over thirty years. "
          }
          {
            "Located in the Greater Vernon area we pride ourselves on excellent quality merchandise and outstanding customer service. "
          }
          {"We carry world renown labels such as  "}
          {"Au Noir, 7 Downie St., Bertigo, 34 Heritage, Alberto, "}
          {
            "Horst, Tommy Bahama, Sergio Barone, Empire Clothing, Jack "
          }
          {
            "Victor, Bugatchi, 2Undr, Pullin and other well known brands since we started in 1992."
          }
          {"\n"}
          {"\n"}
          {
            "Milano Clothing Company has positioned itself as one of the best menswear "
          }
          {
            "store in the Okanagan by being the first one to introduce well known labels "
          }
          {
            "in the area. Our store has thrived since its opening because of quality "
          }
          {"merchandise, great service and a very convenient location."}
        </p>
      </Container>
    );
  }
}
