import React, { Component } from "react";
import classes from "./Contact.module.css";
import { Container, Button } from "react-bootstrap";

export default class Contact extends Component {
  render() {
    return (
      <Container className={classes.Contact}>
        <h3 style={{ color: "#c5b53b;" }}>
          {"Located in the Village Green Mall"}
        </h3>
        <Button
          className={classes.Button}
          href="https://www.google.ca/maps/place/Milano+Clothing+Company/@50.283914,-119.269307,17z/data=!4m7!1m4!3m3!1s0x537dd88f0047a3cb:0x3161c4e21a73b0a5!2sMilano+Clothing+Company!3b1!3m1!1s0x537dd88f0047a3cb:0x3161c4e21a73b0a5"
          bsStyle="primary"
          target="_blank"
        >
          {" "}
          Google Maps
        </Button>

        <p style={{ color: "#c5b53b" }}>{"Milano Clothing Company"}</p>
        <p>
          {"4900 27th St. Vernon, BC \n"}
          {"V1T-7G7 \n"}
          Ph./Fax : {""}
          <a href="tel:+12505491688">(250) 549-1688</a>
          {"\n"}
          E-mail:{" "}
          <a href="mailto: saleem@milanoclothingcompany.com">
            saleem@milanoclothingcompany.com
          </a>
        </p>
        {"\n"}
        <Container className={classes.Hours}>
        <p style={{ color: "#c5b53b;" }}>
          {"10 AM - 6 PM \n"}
          {"10 AM - 8 PM Friday \n"}
          {"11 AM - 5 PM Sunday \n"}
        </p>
        </Container>
        {/* <table>
         <th> Hours of Operation
          <tr>Monday: <td>10AM - 5PM</td></tr>
          <tr>Tuesday: <td>10AM - 5PM</td></tr>
          <tr>Wednesday: <td>10AM - 5PM</td></tr>
          <tr>Thursday: <td>10AM - 7PM</td></tr>
          <tr>Friday: <td>10AM - 7PM</td></tr>
          <tr>Saturday: <td>10AM - 5PM</td></tr>
          <tr>Sunday: <td>10AM - 5PM</td></tr>
          </th>
        </table>
        <p>{"* Hours may change due to holidays and long weekends"}</p> */}
      </Container>
    );
  }
}
