import React, { Component } from "react";
import classes from "./Services.module.css";
import { Container, Jumbotron, Row, Card, CardGroup } from "react-bootstrap";
import { CardHeader } from "react-bootstrap/Card";

export default class Services extends Component {
  render() {
    return (
      <Container className={classes.Services}>
        <CardGroup>
          <Card className={classes.Card}>
            <Card.Title className={classes.Title}>
              {"Tuxedo/Suit Rentals"}
            </Card.Title>
            <Card.Body>
              {/*"We are an agent for Black & Lee tuxedo and suit rentals. "*/}
              {
                "Visit us for a free rental catalouge and order your rental outfit today."
              }
            </Card.Body>
          </Card>
          <Card className={classes.Card}>
            <Card.Img
              className={classes.Img}
              variant="top"
              src="./assets/milano-services/tailor.jpg"
            />
            <Card.Title className={classes.Title}>
              {"In-house Tailoring Services"}
            </Card.Title>
            <Card.Body>
              {
                "We provide full tailoring services to all of our customers clothing."
              }
            </Card.Body>
          </Card>
          <Card className={classes.Card}>
            <Card.Img
              className={classes.Img}
              variant="top"
              src="./assets/milano-services/sergio-tailor.jpg"
            />
            <Card.Title className={classes.Title}>
              {"Custom Tailored Suits"}
            </Card.Title>
            <Card.Body>
              {
                "We provide custom tailoring from our select fabrics to suit your needs. "
              }
              {""}
            </Card.Body>
          </Card>
        </CardGroup>
      </Container>
    );
  }
}
