import React, { Component } from "react";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";
import classes from "./CustomNavbar.module.css";

export default class CustomNavbar extends Component {
  render() {
    return (
      <Navbar className={classes.Navbar} expand="lg" sticky="top">
        <img src={"./assets/milano-home/Milano-logo.jpg"} alt="Image not available" />
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav pullRight>
            <Nav.Item>
              <Nav.Link className={classes.Nav} href="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/about">About</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/services" className={classes.Dropdown}>
                Services
                {/*  <NavDropdown>
                  <NavDropdown.Item href="./services">Rentals</NavDropdown.Item>
                </NavDropdown> */}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/gallery">Gallery</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}
