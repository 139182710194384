import React, { Component } from "react";
import { Container, Carousel, Card, CardGroup } from "react-bootstrap";
import { CardHeader } from "react-bootstrap/Card";
// Core modules imports are same as usual
import { Navigation, Pagination } from 'swiper';


import { Slider } from "react-slick";
import classes from "./Gallery.module.css";

const galleryContainer = document.querySelector(".react-gallery");

let images = [
  {
    id: 1,
    src: "./assets/Milano-store.jpg",
    title: "store front",
    description: "our store front display",
  },
  {
    id: 2,
    src: "./assets/Milano-store.jpg",
    title: "store front",
    description: "our store front display",
  },
];

export default class Gallery extends Component {
  
  render() {
    /*     const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      arrows: true,
      slidesToScroll: 1,
    };
 */
    return (
      <Container className={classes.Gallery}>
        <Carousel nextLabel="next">
          <Carousel.Item>
            <img
              className={classes.galleryimg}
              src="./assets/milano-gallery/img-I.jpg"
            />
            <Carousel.Caption>
              <p>Suit collection</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className={classes.galleryimg}
              src="./assets/milano-gallery/img-II.jpg"
            />
            <Carousel.Caption>
              <p>Our select few of many ties we have available</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className={classes.galleryimg}
              src="./assets/milano-gallery/img-III.jpg"
            />
            <Carousel.Caption>
              <p>Front display</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className={classes.galleryimg}
              src="./assets/milano-gallery/img-IV.jpg"
            />
            <Carousel.Caption>
              <p>Front table</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className={classes.galleryimg}
              src="./assets/milano-gallery/img-V.jpg"
            />
            <Carousel.Caption>
              <p>Shelf stock</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className={classes.galleryimg}
              src="./assets/milano-gallery/img-VI.jpg"
            />
            <Carousel.Caption>
              <p>Socks & underwear</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className={classes.galleryimg}
              src="./assets/milano-gallery/img-VII.jpg"
            />
            <Carousel.Caption>
              <p>Tommy Bahama beach wear</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>
    );
  }
}
