import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Image, Button } from "react-bootstrap";
import classes from "./Home.module.css";

export default class Home extends Component {
  render() {
    return (
        <Container className={classes.Home}>
        <img src="./assets/milano-home/Milano-store.jpg" />
          <h3>{"Welcome to Milano Clothing Company"}</h3>
          <p>{"The finest in mens clothing"} </p>
          <Link to="/about">
    <Button className={classes.button} bsStyle="primary">
              About
            </Button>
          </Link>
        </Container>
    );
  }
}
