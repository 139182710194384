import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import classes from "./SocialMedia.module.css";

export default class SocialMedia extends Component {
  render() {
    return (
      <div className={classes.SocialMedia}>
        <h3>Follow and Like us on Facebook: </h3>
        <a
          href="https://www.facebook.com/Milano-Clothing-Company-336979036406086"
          className="Facebook-social"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFacebookF} size="2x" />
        </a>
      </div>
    );
  }
}
